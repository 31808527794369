import * as axi from "axios"

const { app_current_locale, token } = window

if (token) {
    localStorage.setItem("token", token)
}

const baseURL = "/api/v1/"

const axios = axi.create({
    baseURL,
    headers: {
        common: {
            Authorization: `Bearer ${token}`,
            "X-Language": app_current_locale
        }
    }
})

const redirect = function () {
    // document.location.replace(`${app_url}login`)
}

axios.interceptors.request.use(function (request) {
    if (request.url.startsWith(baseURL)) {
        request.url = request.url.replaceAll(baseURL, "")
    }
    return request
})

axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            redirect()
        }
        return Promise.reject(error)
    }
)

export default axios
